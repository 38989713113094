import Resizer from 'react-image-file-resizer';

const resize = async (base64: string, removeHeader = false) => {
  const base64String = base64.startsWith('data:image/')
    ? base64
    : 'data:image/jpeg;base64,' + base64;
  const blob = await base64ToBlob(base64String);

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      blob,
      1280,
      1280,
      'JPEG',
      80,
      0,
      async (newBase64) => {
        if(removeHeader) {
          resolve((newBase64 as string).split(',')[1]);
        } else {
          resolve(newBase64);
        }
      },
      'base64',
    );
  });
};

const base64ToBlob = (base64: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    try {
      fetch(base64)
        .then((res) => res.blob())
        .then((blod) => resolve(blod));
    } catch (error) {
      reject(error);
    }
  });
};

const ResizeImageHelper = {
  resize,
  base64ToBlob,
};

export default ResizeImageHelper;