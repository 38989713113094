import { Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { CaseSelectors } from '../core/adapters/redux/reducer/case';
import { connect } from 'react-redux';
import Loading from '../components/loading';
const Home = lazy(() => import('../screens/home'));
const TestVideoLandscape = lazy(
  () => import('../screens/testModel/TestVideoLandscape'),
);
const TestVideoPortrait = lazy(
  () => import('../screens/testModel/TestVideoPortrait'),
);
const TestVideocloud = lazy(
  () => import('../screens/testModel/TestVideocloud'),
);


const TestImage = lazy(() => import('../screens/testModel/TestImage'));
const TestRecord = lazy(() => import('../screens/testModel/TestRecord'));

type Props = {
  appMode: string;
};

const AuthenticationRoutes = (props: Props) => {
  const { appMode } = props;
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        {appMode === 'development' && (
          <>
            <Route
              path="/testvideolandscape"
              element={<TestVideoLandscape />}
            />
            <Route path="/testvideoportrait" element={<TestVideoPortrait />} />
            <Route path="/TestVideocloud" element={<TestVideocloud />} />
            
            <Route path="/testimg" element={<TestImage />} />
            <Route path="/testrecord" element={<TestRecord />} />{' '}
          </>
        )}
        <Route path="/*" element={<Home />} />
      </Routes>
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  appMode: CaseSelectors.getAppMode(state),
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthenticationRoutes);
