import ApiService from './api';
import { chunk, forEach, keys, padStart, toString } from 'lodash';
import { IS_IPHONE } from '../../constants';
import ResizeImageHelper from '../../helpers/resizeImage';

class UploadService {
  upload = async (params) => {
    const {
      image: base64img,
      caseID,
      category,
      bin,
      time,
      refTime,
      overRef,
      overPostRef,
    } = params;

    let uploadKey = category;
    if (category === 'OVERVIEW_BIN') {
      if (overRef) {
        uploadKey = `OVERVIEW_REF_BIN_${bin}_time_${time}`;
      } else if (overPostRef) {
        uploadKey = `OVERVIEW_POST_REF_BIN_${bin}_time_${time}`;
      } else {
        uploadKey = `${category}_${bin}_time_${time}`;
      }
    } else if (category === 'OVERVIEW_360') {
      uploadKey = `OVERVIEW_360_time_${time}`;
    } else if (category === 'ZOOMIN_TIME') {
      uploadKey = `${category}_reftime_${refTime}_${time}`;
    } else if (category === 'REF_TIME') {
      uploadKey = `${category}_${refTime}`;
    } else if (category === 'TRANSITION') {
      uploadKey = `transition_time_${refTime
        .toString()
        .split('_')
        .join('')}_${time}`;
    }

    return await fetch(base64img)
      .then((res) => res.blob())
      .then(async (image) => {
        const file = new File([image], `${uploadKey.toLowerCase()}.jpg`, {
          type: 'image/jpeg',
        });

        const bucketName = process.env.REACT_APP_S3_BUCKET;
        const s3Url = process.env.REACT_APP_S3_HOST_URL;
        const response = await ApiService.uploadAnyS3({
          bucket: `${bucketName}/${caseID}/input`,
          upload_key: `${uploadKey.toLowerCase()}.jpg`,
          file: file,
          case_id: caseID,
        });
        const { key } = response;
        if (category === 'TRANSITION') {
          return {
            category,
            bin,
            time: time,
            refTime: refTime,
            url: `${s3Url}${key}`,
            s3_bucket: bucketName,
            s3_path: `${caseID}/input/${uploadKey.toLowerCase()}.jpg`,
          };
        }
        if (category === 'ZOOMIN_TIME' || category === 'REF_TIME') {
          return {
            category,
            bin,
            zoomin_time: time,
            url: `${s3Url}${key}`,
            s3_bucket: bucketName,
            s3_path: `${caseID}/input/${uploadKey.toLowerCase()}.jpg`,
          };
        } else {
          return {
            category,
            bin,
            time,
            url: `${s3Url}${key}`,
            s3_bucket: bucketName,
            s3_path: `${caseID}/input/${uploadKey.toLowerCase()}.jpg`,
          };
        }
      });
  };

  uploadLog = async (data) => {
    const { log, caseID } = data;

    const s3ObjectName = `${caseID}.txt`;

    const textLog = JSON.stringify(log);

    const blob = new Blob([textLog], { type: 'text/plain' });
    const file = new File([blob], s3ObjectName, {
      type: 'text/plain',
    });
    const response = await ApiService.uploadAnyS3({
      bucket: `${process.env.REACT_APP_S3_BUCKET}/${caseID}/log`,
      upload_key: s3ObjectName,
      file: file,
      case_id: caseID,
    });
    console.log('Tải lên S3 thành công. Đường dẫn tới tệp:', response);
  };

  uploadVideo = async (data) => {
    const { video, caseID } = data;

    const videoKeys = keys(video);
    let index = 1;
    for (const imageKeys of chunk(videoKeys, 20)) {
      const videoPart = {};
      for (const imageKey of imageKeys) {
        videoPart[imageKey] = await ResizeImageHelper.resize(video[imageKey], true)
      }
      const jsonVideo = JSON.stringify(videoPart);
      const blob = new Blob([jsonVideo], { type: 'application/json' });
      const refImageName = `refImages_${padStart(
        toString(index),
        3,
        '0',
      )}.json`;
      try {
        const jsonFile = new File([blob], refImageName, {
          type: 'application/json',
        });
        const response = await ApiService.uploadAnyS3({
          bucket: `${process.env.REACT_APP_S3_BUCKET}/${caseID}/input`,
          upload_key: refImageName,
          case_id: caseID,
          file: jsonFile,
        });
        console.log('Tải lên S3 thành công. Đường dẫn tới tệp:', response);
      } catch (error) {
        console.log('Debug ~ UploadService ~ uploadVideo ~ error:', error);
      }
      index += 1;

    }
  };
}

export default new UploadService();