import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { UploadAnyS3Params, UploadResult } from './types';

// let failedQueue: any = [];

class ApiService {
  BASE_URL = process.env.REACT_APP_HOST_URL;

  TIMEOUT = 120000;

  INSTANCE: AxiosInstance = undefined;

  TOKEN = '';

  Interceptors = [];

  constructor() {
    if (!this.INSTANCE) {
      this.INSTANCE = axios.create({
        baseURL: this.BASE_URL,
        timeout: this.TIMEOUT,
      });
    }
  }

  // Create request queue
  // processQueue = (error, token = null) => {
  //   failedQueue.forEach((prom: any) => {
  //     if (error) {
  //       prom.reject(error);
  //     } else {
  //       prom.resolve(token);
  //     }
  //   });

  //   failedQueue = [];
  // };

  addAuthorizationHeader = (token: string) => {
    token && (this.TOKEN = token);
    const interceptorId = this.INSTANCE.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
    this.Interceptors.push(interceptorId);
    return interceptorId;
  };

  removeAllInterceptor = () => {
    this.Interceptors.map((interceptorId) =>
      this.INSTANCE.interceptors.request.eject(interceptorId),
    );
  };

  login = async (params) => {
    const response = await this.INSTANCE.post('auth/login', params);
    const { result } = response.data;
    const { access_token } = result;
    Cookies.set('access_token', access_token);
    this.addAuthorizationHeader(access_token);
    return access_token;
  };

  register = async (params) => {
    const response = await this.INSTANCE.post('auth/register', params);
    return response.data;
  };

  logout = async () => {
    Cookies.remove('access_token');
    this.removeAllInterceptor();
  };

  fetchUser = async () => {
    const response = await this.INSTANCE.get('users');
    return response.data;
  };

  fetchCases = async () => {
    const response = await this.INSTANCE.get('cases/all');
    return response.data;
  };

  fetchCaseById = async (id) => {
    const response = await this.INSTANCE.get(`cases?id=${id}`);
    return response.data;
  };

  createCase = async () => {
    const response = await this.INSTANCE.post('cases');
    return response.data;
  };

  updateCaseById = async (params) => {
    const response = await this.INSTANCE.put(`cases`, params);
    return response.data;
  };

  updateCaseImagesById = async (id, params) => {
    const response = await this.INSTANCE.post(`imgs?case_id=${id}`, params);
    return response.data;
  };

  redoById = async (id) => {
    const response = await this.INSTANCE.get(`cases/redo/?id=${id}`);
    return response.data;
  };

  uploadAnyS3 = async (params: UploadAnyS3Params): Promise<UploadResult> => {
    const form = new FormData();
    form.append('bucket', params.bucket);
    form.append('upload_key', params.upload_key);
    form.append('case_id', params.case_id);

    if (params.file_content_str) {
      form.append('file_content_str', params.file_content_str);
    }
    if (params.file) {
      form.append('file', params.file);
    }
    const response = await this.INSTANCE.post('imgs/uploadany', form);
    return response.data;
  };
}

export default new ApiService();
